import React from 'react'

import Page from '../../components/Page'
import SEO from '../../components/SEO'
import Schema from '../../components/Schema'
import DemoForm from '../../components/forms/Demo'
import Newsletter from '../../components/Newsletter'

const WholesalersDemoPage = () => {
  const breadcrumbs = [
    {
      name: 'For Wholesalers',
      uri: '/wholesalers',
    },
    {
      name: 'Sign up - Wholesalers',
      uri: '/wholesalers/demo',
    },
  ]

  return (
    <Page className={`p-sign-up p-forWholesalers has-fingerPrints`}>
      <SEO title="Sign up - Wholesalers" />
      <Schema breadcrumbs={breadcrumbs} />

      <section className="c-section c-section--pageHeader">
        <div className="c-section__outer container">
          <div className="c-section__inner">
            <div className="c-pageHeader">
              <div className="row">
                <div className="col-12 col-lg-3">
                  <span className="c-pageHeader__title">
                    <strong>Demo</strong> sign up
                  </span>
                </div>
                <div className="col-12 col-lg-9">
                  <h1 className="c-pageHeader__intro">
                    <strong>Wholesalers</strong>, ready to find out how Erudus
                    can work for you?
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="c-section c-section--contact-us">
        <div className="c-section__outer container">
          <div className="c-section__inner pt-0 pb-1">
            <div className="c-sectionTitle">
              <div className="row">
                <div className="col-12 col-lg-8 offset-lg-3">
                  <DemoForm formName="Wholesalers Demo Sign Up Form" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Newsletter />
    </Page>
  )
}

export default WholesalersDemoPage
